import React from "react";

export default function CodeTab({ codeShow, handleCodeShow, code }) {
  return (
    <>
      <button
        className={`font-medium text-base  py-1 px-4 rounded shadow-card ${
          codeShow === code ? "bg-primary text-white" : "bg-white text-black"
        } `}
        onClick={() => handleCodeShow(code)}
      >
        {code}
      </button>
    </>
  );
}
