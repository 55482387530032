import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import React, { useState } from "react";
import Clipboard from "react-clipboard.js";
import CodeTab from "../components/CodeTab";
import Layout from "../components/layout";
import Seo from "../components/seo";

const TemplateDetails = ({ data }) => {
  const [codeShow, setCodeShow] = useState("HTML");
  const handleCodeShow = (show) => {
    setCodeShow(show);
  };

  const [copyText, setCopyText] = useState("Copy");

  const notify = () => {
    setCopyText("Copied!");
    setInterval(() => {
      setCopyText("Copy");
    }, 3000);
  };

  const image = getImage(data.mdx.frontmatter.featured_image);
  return (
    <Layout>
      <Seo
        title={data.mdx.frontmatter.title}
        description={data.mdx.frontmatter.excerpt}
      />
      <div className="bg-[#F5F8FF] pt-32 md:pt-44 pb-16">
        <div className="container">
          <div className="flex flex-wrap justify-center -mx-4">
            <div className="w-full px-4">
              <div className="max-w-[690px] mx-auto mb-[60px] text-center">
                <h2 className="font-bold text-3xl md:text-4xl xl:text-[40px] leading-tight md:leading-tight xl:leading-tight text-black text-center mb-4">
                  {data.mdx.frontmatter.title}
                </h2>
                <p className="font-medium text-body-color text-center ">
                  {data.mdx.frontmatter.excerpt}
                </p>
              </div>

              <div className="bg-white rounded-lg shadow-card lg:flex">
                <div className="w-full lg:w-5/12 lg:border-r border-[#eee]">
                  <div className="p-9">
                    <div className="bg-[#F5F8FF] h-14 px-5 rounded shadow-card flex items-center mb-5">
                      <span className="mr-2 text-primary">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 22 22"
                          className="fill-current"
                        >
                          <path d="M11.0001 8.25C10.2707 8.25 9.57126 8.53973 9.05554 9.05546C8.53981 9.57118 8.25008 10.2707 8.25008 11C8.25008 11.7293 8.53981 12.4288 9.05554 12.9445C9.57126 13.4603 10.2707 13.75 11.0001 13.75C11.7294 13.75 12.4289 13.4603 12.9446 12.9445C13.4604 12.4288 13.7501 11.7293 13.7501 11C13.7501 10.2707 13.4604 9.57118 12.9446 9.05546C12.4289 8.53973 11.7294 8.25 11.0001 8.25ZM11.0001 15.5833C9.78451 15.5833 8.61872 15.1004 7.75917 14.2409C6.89963 13.3814 6.41675 12.2156 6.41675 11C6.41675 9.78442 6.89963 8.61864 7.75917 7.75909C8.61872 6.89955 9.78451 6.41667 11.0001 6.41667C12.2157 6.41667 13.3814 6.89955 14.241 7.75909C15.1005 8.61864 15.5834 9.78442 15.5834 11C15.5834 12.2156 15.1005 13.3814 14.241 14.2409C13.3814 15.1004 12.2157 15.5833 11.0001 15.5833ZM11.0001 4.125C6.41675 4.125 2.50258 6.97583 0.916748 11C2.50258 15.0242 6.41675 17.875 11.0001 17.875C15.5834 17.875 19.4976 15.0242 21.0834 11C19.4976 6.97583 15.5834 4.125 11.0001 4.125Z" />
                        </svg>
                      </span>
                      <span className="font-medium text-base text-black">
                        Preview
                      </span>
                    </div>
                    <div className=" bg-white rounded-lg flex items-center justify-center">
                      <div className="w-full p-10 lg:p-8 xl:p-10">
                        <GatsbyImage
                          image={image}
                          alt={data.mdx.frontmatter.featured_image_alt}
                          className="rounded w-full"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-7/12">
                  <div className="p-9">
                    <div className="bg-[#F5F8FF] h-14 px-5 rounded shadow-card flex items-center mb-5 space-x-4">
                      <CodeTab
                        handleCodeShow={handleCodeShow}
                        codeShow={codeShow}
                        code="HTML"
                      />
                      <CodeTab
                        handleCodeShow={handleCodeShow}
                        codeShow={codeShow}
                        code="Tailwind"
                      />
                    </div>

                    <div className="relative">
                      <Clipboard
                        className="py-2 px-5 rounded-lg text-body-color font-semibold text-sm absolute top-4 right-4 z-50 bg-white hover:bg-primary hover:text-white"
                        data-clipboard-text={
                          codeShow === "HTML"
                            ? data.mdx.frontmatter.htmlCode.childMdx.internal
                                .content
                            : data.mdx.frontmatter.tailwindCode.childMdx
                                .internal.content
                        }
                        onClick={notify}
                      >
                        {copyText}
                      </Clipboard>

                      {codeShow === "HTML" && (
                        <MDXRenderer>
                          {data.mdx.frontmatter.htmlCode.childMdx.body}
                        </MDXRenderer>
                      )}

                      {codeShow === "Tailwind" && (
                        <MDXRenderer>
                          {data.mdx.frontmatter.tailwindCode.childMdx.body}
                        </MDXRenderer>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default TemplateDetails;

// $slug is a context passed from gatsby-node.js, the context named 'slug' during the createPage() action
export const query = graphql`
  query($slug: String!) {
    mdx(slug: { eq: $slug }) {
      frontmatter {
        title
        excerpt
        featured_image_alt
        featured_image {
          childImageSharp {
            gatsbyImageData
          }
        }
        htmlCode {
          childMdx {
            body
            internal {
              content
            }
          }
        }
        tailwindCode {
          childMdx {
            body
            internal {
              content
            }
          }
        }
      }

      body
    }
  }
`;
